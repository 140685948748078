/**
 *
 * 404 (Not Found) Page
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Link from 'components/Elements/Link';

const NotFoundPage = props => {
  return (
    <Container>
      <h1>We're sorry but this page cannot be found.</h1>
      <Link to="/">Back to Home</Link>
    </Container>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.any,
};
export default NotFoundPage;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url('/images/site-bg.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    color: white;
  }
  a {
    color: white;
    font-size: 2.4em;
    border: 2px solid white;
    padding: 0.5em;
    margin: 2em 0;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: white;
    }
  }
`;
